<template>
  <FocusBar :save="saveForm">
    <template #middle>
      <div class="justify-center items-center hidden md:flex">
        <div class="flex justify-center items-center w-fit gap-3 mx-4">
          <Inplace>
            <template #display>
              <h2 class="!font-medium">{{ form_name || 'Enter form name' }}</h2>
            </template>
            <template #content>
              <Field placeholder="Form name" v-model="form_name" type="text" />
            </template>
          </Inplace>
          <font-awesome-icon icon="pencil" class="text-cool-gray-800" />
        </div>
      </div>
    </template>
    <template #before-changes>
      <Btn v-if="form_id" @click="viewPreview" severity="tertiary">
        <template #icon>
          <font-awesome-icon :icon="['far', 'eye']" />
        </template>
        <span class="hidden md:block">Web preview</span>
      </Btn>
    </template>
  </FocusBar>
</template>

<script setup>
import { computed } from 'vue'
import useForm from '@/components/forms/Form'
import FocusBar from '../layout/header/FocusBar.vue'
import Inplace from '@/components/ui/Inplace.vue'
import RouteEntityContext from '../composables/RouteEntityContext'
import EntityComputedFields from '../composables/EntityFields/EntityComputedFields'

const { refId, type, storeName } = RouteEntityContext.useRouteEntityContext({
  trackChanges: false
})

const { form_name, form_id } = EntityComputedFields.useEntityComputedFields({
  refId,
  type,
  store: storeName
})

const { saveForm } = useForm()

const publicFormLink = computed(() => {
  const baseUrl = `${import.meta.env.VITE_FE_PROTOCOL}${import.meta.env.VITE_FE_BASE}`
  return `${baseUrl}/customForm/${form_id.value}`
})

const viewPreview = () => {
  window.open(publicFormLink.value, '_blank')
}
</script>
